<script>
import Left from '@/components/pages/page4/Left.vue'
import Right from '@/components/pages/page4/Right.vue'
export default { components: { Left, Right } }
</script>

<template>
  <div class="page3__headerContainer">
    <div class="page4 page__bg">
      <div class="page2__container page4__container">
        <div class="row">
          <div class="col-xl-6 col-12 page4__col">
            <Left />
          </div>
          <div class="col-6 d-xl-block d-none">
            <Right />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

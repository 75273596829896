<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
export default {
  components: { DatePicker },
  props: ['inputData', 'inputContainerExternalClass'],
  data() {
    return {
      date: null,
    }
  },
  watch: {
    inputData: {
      handler(input) {
        if (input && input.initialValue) {
          const mydate = new Date(input.initialValue)

          this.date = mydate
        }
      },

      deep: true,
    },
    date(value) {
      const currentDate = new Date(value).toISOString().substring(0, 10)
      this.$emit('onPick', {
        title: this.inputData.title,
        value: currentDate,
      })
    },
  },
}
</script>

<template>
  <ValidationProvider v-slot="{ errors, valid }" :rules="inputData.rules">
    <div class="FormInput " :class="inputContainerExternalClass">
      <label :for="inputData.heading" class="FormInput__inputContainer">
        <p class="FormInput__heading">
          <span>{{ inputData.heading }}</span>
          <span v-if="inputData.require" class="text-danger">*</span>
        </p>
        <DatePicker
          v-model="date" :state="errors[0] ? false : valid ? true : null" prefix-class="mx"
          type="date"
        />
        <p class="text-secondary FormInput__input__error">{{ errors[0] }}</p>
      </label>
    </div>
  </ValidationProvider>
</template>

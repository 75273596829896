<script>
import Left from '@/components/common/Greetings.vue'
import SmallScreenContent from '@/components/common/SmallScreenContent.vue'
import PageContent from '@/components/pages/page4/Right.vue'
export default {
  components: {
    Left,
    SmallScreenContent,
    PageContent,
  },
}
</script>

<template>
  <div>
    <div class="d-xl-block d-none">
      <Left />
    </div>
    <div class="d-xl-none d-block">
      <SmallScreenContent iconbtnexternal-class="page4__iconbtn">
        <template #pagecontent2>
          <PageContent />
        </template>
      </SmallScreenContent>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
import { BFormSelect } from 'bootstrap-vue'

export default {
  components: { BFormSelect },
  props: ['inputData', 'inputContainerExternalClass'],
  data() {
    return {
      selected: null,
    }
  },
  watch: {
    inputData: {
      handler(input) {
        if (input && input.initialValue)
          this.selected = input.initialValue.value

        if (input.title === 'region')
          this.$store.commit('setCities', this.selected)

        if (input.title === 'city')
          this.$store.commit('setZones', this.selected)

        if (this.selected) {
          this.$emit('getSelectVal', {
            title: input.title,
            value: this.selected,
          })
        }
      },

      deep: true,
    },
  },

  methods: {
    setInputVal(title) {
      if (title === 'region')
        this.$store.commit('setCities', this.selected)
      else if (title === 'city')
        this.$store.commit('setZones', this.selected)

      this.$emit('getSelectVal', { title, value: this.selected })
    },
  },
}
</script>

<template>
  <ValidationProvider v-slot="{ errors, valid }" :rules="inputData.rules">
    <div class="FormInput " :class="inputContainerExternalClass">
      <label :for="inputData.heading" class="FormInput__inputContainer">
        <p class="FormInput__heading">
          <span>{{ inputData.heading }}</span>
          <span v-if="inputData.require" class="text-danger">*</span>
        </p>
        <BFormSelect
          v-model="selected" :options="inputData.options" :state="errors[0] ? false : valid ? true : null"
          @change="setInputVal(inputData.title)"
        />
        <p class="text-secondary FormInput__input__error">{{ errors[0] }}</p>
      </label>
    </div>
  </ValidationProvider>
</template>

<style lang="scss" scoped>

</style>

<script>
import Page4 from '@/components/pages/page4.vue'

export default { components: { Page4 } }
</script>

<template>
  <div>
    <Page4 />
  </div>
</template>

<style lang="scss" scoped>

</style>

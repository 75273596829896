<script>
// import RadioButtonContent from "@/components/ui/RadioBtnContent.vue";
import { ValidationObserver } from 'vee-validate'
import { BForm } from 'bootstrap-vue'
import RadioBtnsContent from '@/components/ui/editcustomerradiobtn.vue'
import BottomRadioButtons from '@/components/ui/BottomRadioButtons.vue'
import Input from '@/components/ui/multistepInput.vue'
import Button from '@/components/ui/Button.vue'
import Select from '@/components/ui/editcustomerSelect.vue'
import DatePicker from '@/components/ui/editcustomerDatepicker.vue'

export default {
  components: {
    ValidationObserver,
    RadioBtnsContent,
    BottomRadioButtons,
    Input,
    Button,
    Select,
    DatePicker,
    BForm,
  },
  data() {
    return {
      submitted: false,
      showspinner: false,
      RadioData: [
        {
          id: 'aasa242425dasdafas',
          heading: 'Civilité',
          title: 'civility',
          initialValue: '',
          from: 'page1',
          rquired: true,
          rules: 'required',
          colClass: 'FormRadio__colSecond  page1__FormRadio__colSecond',
          RadioBtnMainContainerClass:
                        'FormRadio__second d-flex justify-content-between',
          options: [
            {
              text: 'Madame',
              value: 'Mme.',
              class: 'Madame ',
            },
            {
              text: 'Monsieur',
              value: 'Mr.',
              class: 'Monsieur second',
            },
          ],
        },
      ],
      BottomRadioData: [
        {
          id: 'axc23fvc13',
          required: false,
          options: [
            {
              textContainerClass: 'FormRadio__btn__textContainer',
              text1class: 'text1',
              text2class: 'text2',
              text: 'Je souhaite recevoir la newsletter',
              text2:
                                '(promotions, nouveautés, promis nous ne faisons pas de spam)',
              value: 'newsletter',
              class: 'newsletter ',
            },
            {
              textContainerClass: 'FormRadio__btn__textContainer',
              text1class: 'text3',
              text:
                                'Je souhaite recevoir les conseils, les offres et les actualités de nos <br> partenaires sélectionnés',
              value: 'offers',
              class: 'offers',
            },
          ],
        },
      ],
      inputs: [
        {
          require: true,
          heading: 'Nom',
          type: 'text',
          component: 'Input',
          title: 'firstName',
          from: 'page1',
          rules: 'required',
        },
        {
          require: true,
          heading: 'Prénom',
          title: 'lastName',
          type: 'text',
          component: 'Input',
          rules: 'required',
          from: 'page1',
        },
        {
          require: true,
          heading: 'Date de naissance',
          type: 'datepicker',
          component: 'DatePicker',
          title: 'birthDate',
          rules: 'required',
          from: 'page1',
        },
        {
          rules: 'required',
          require: true,
          heading: 'Addresse',
          type: 'text',
          component: 'Input',
          title: 'street',
          from: 'page1',
        },
        {
          rules: 'required',
          require: true,
          heading: 'Region',
          title: 'region',
          type: 'select',
          component: 'Select',
          from: 'page1',
          options: this.$store.state.regions,
        },
        {
          rules: 'required',
          require: true,
          heading: 'Ville',
          title: 'city',
          type: 'select',
          component: 'Select',
          from: 'page1',
          options: [
            {
              value: null,
              text: 'Select City',
            },
          ],
        },

        {
          rules: 'required',
          require: true,
          heading: 'Zone',
          title: 'zone',
          type: 'select',
          component: 'Select',
          from: 'page1',
          options: [
            {
              value: null,
              text: 'Select Zone',
            },
          ],
        },

        {
          rules: 'required|email',
          require: true,
          heading: 'Email',
          title: 'email',
          type: 'email',
          component: 'Input',
          from: 'page1',
        },
        {
          rules: 'required|numeric',
          require: true,
          heading: 'Téléphone',
          type: 'text',
          component: 'Input',
          title: 'phoneNumber',
          from: 'page1',
        },
        {
          rules: 'required',
          require: true,
          heading: 'Mot de passe',
          type: 'password',
          title: 'password',
          component: 'Input',
          from: 'page1',
        },
        // {
        //   rules: "required",
        //   require: true,
        //   heading: "Confirmation mot de passe",
        //   type: "password",
        //   title: "confirmPassword",
        //   component: "Input",
        //   from: "page1",
        // },
      ],
      url: 'regions',
      message: '',
      password: '',
      confirmPassword: '',

      form: {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        civility: null,
        birthDate: null,
        phoneNumber: null,
        originWebsite: 'AIN_SAISS',

        addresses: [
          {
            street: null,
            region: {
              id: '',
            },
            city: {
              id: '',
            },
            zone: {
              id: '',
            },
            addressType: 'Facturation',
          },
          {
            street: null,
            region: {
              id: '',
            },
            city: {
              id: '',
            },
            zone: {
              id: '',
            },
            addressType: 'Livraison',
          },
        ],
      },
    }
  },
  computed: {
    Regions() {
      return this.$store.state.regions
    },
  },

  watch: {
    '$store.state.status': function (message) {
      this.message = message
    },
    '$store.state.regions': function (regions) {
      const regionOptions = regions.map((region) => {
        return { value: region.id, text: region.name }
      })

      regionOptions.unshift({
        value: null,
        text: 'Select Region',
      })
      this.inputs = this.inputs.map((input) => {
        if (input.title == 'region')
          input.options = regionOptions

        return input
      })
    },
    '$store.state.cities': function (cities) {
      const cityOptions = cities.map((city) => {
        return { value: city.id, text: city.name }
      })

      cityOptions.unshift({
        value: null,
        text: 'Select City',
      })
      this.inputs = this.inputs.map((input) => {
        if (input.title == 'city')
          input.options = cityOptions

        return input
      })
    },
    '$store.state.zones': function (zones) {
      const zoneOptions = zones.map((zone) => {
        return { value: zone.id, text: zone.name }
      })
      zoneOptions.unshift({
        value: null,
        text: 'Select Zone',
      })
      this.inputs = this.inputs.map((input) => {
        if (input.title == 'zone')
          input.options = zoneOptions

        return input
      })
    },
  },
  async created() {
    this.$store.commit('clearform1')
    this.$store.dispatch('setRegion', this.url)

    if (this.$cookie.get('saisstoken')) {
      const user = await this.$store.dispatch('getUser', { url: 'auth/me' })
      const customer = await this.$store.dispatch('getCustomer', {
        url: `customers/${user.id}`,
      })

      this.RadioData[0].initialValue = customer.civility

      this.inputs = this.inputs.filter((input) => {
        if (input.title == 'phoneNumber')
          this.$set(input, 'initialValue', customer.phoneNumber)

        if (input.title == 'birthDate')
          this.$set(input, 'initialValue', customer.birthDate)

        if (user.hasOwnProperty(input.title)) {
          // input.initialValue = user[input.title];

          this.$set(input, 'initialValue', user[input.title]) // good for reactivity
        }
        return input
      })
    }
  },
  methods: {
    getSelectVal(input) {
      if (input.title == 'region') {
        this.form.addresses[0].region.id = input.value
        this.form.addresses[1].region.id = input.value
      }
      else if (input.title == 'city') {
        this.form.addresses[0].city.id = input.value
        this.form.addresses[1].city.id = input.value
      }
      else if (input.title == 'zone') {
        this.form.addresses[0].zone.id = input.value
        this.form.addresses[1].zone.id = input.value
      }
    },
    getDateValue(input) {
      this.form[input.title] = input.value
    },
    getRadiobtnValue(input) {
      this.form[input.title] = input.value
      console.log('input', input)
      console.log('form', this.form)
    },
    getInputValue(input) {
      if (input.title == 'password') {
        this.password = input.value
        this.form[input.title] = input.value
        return
      }
      if (input.title == 'confirmPassword') {
        this.confirmPassword = input.value
        return
      }
      if (input.title == 'street') {
        this.form.addresses[0].street = input.value
        this.form.addresses[1].street = input.value
      }
      this.form[input.title] = input.value
    },
    async submitForm() {
      this.submitted = true
      this.showspinner = true
      if (this.password !== this.confirmPassword)
        return (this.message = 'Password Did not match')

      const status = await this.$store.dispatch('setCustomer', {
        url: 'customers',
        form: this.form,
      })
      if (status)
        this.showspinner = false
    },
  },
}
</script>

<template>
  <div class="page4__right">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
      <BForm ref="myform" @submit.prevent="handleSubmit(submitForm)">
        <div class="row pt-4">
          <div
            v-for="Radio in RadioData" :key="Radio.id" class="col-12 page4__right__radiobtnCol"
            :class="Radio.colClass"
          >
            <RadioBtnsContent
              flex-class="d-flex flex-sm-row flex-column align-items-sm-center page1__formRadiobtnContainer "
              :radio-btn-main-container-class="Radio.RadioBtnMainContainerClass" :heading="Radio.heading"
              :required="Radio.required" :options="Radio.options" :initial-value="Radio.initialValue"
              :title="Radio.title" :rules="Radio.rules" @onSelect="getRadiobtnValue"
            />
          </div>
          <div v-for="input in inputs" :key="input.heading" class="col-md-6 col-12">
            <component
              :is="input.component" :input-data="input" @getInputVal="getInputValue"
              @onPick="getDateValue" @getSelectVal="getSelectVal"
            />
          </div>

          <div class="col-12">
            <div
              v-for="bottomRadio in BottomRadioData" :key="bottomRadio.id"
              class="page1__bottomRadioButtonsContainer"
            >
              <BottomRadioButtons :required="bottomRadio.required" :options="bottomRadio.options" />
              <p class="Form__required page1__required" :class="{ show: submitted }">
                {{ message }}
              </p>
            </div>

            <div class="text-center">
              <Button
                external-class="page1__btn" width="280.15px" height="38.58px" btn-text="Enregistrer"
                :showspinner="showspinner"
              />
            </div>
          </div>
        </div>
      </BForm>
    </ValidationObserver>
  </div>
</template>

<style lang="scss" scoped>

</style>
